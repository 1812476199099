.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero-section .relative.text-center h2.welcome-title {  
  transform: rotate(-5deg) !important;  /* Use !important cautiously */
}

.hero-section {
  min-height: 100vh; 
  padding-top: 60px;
}

.relative.text-center { 
  padding-top: -1rem; 
}

.welcome-title {
  top: .5rem; /* Adjust this value to position it lower */
  left: 0;
  transform: rotate(5deg) !important; 
  z-index: 10;
  transform-origin: top left; 
}

.signup-button {
  background-color: #CFA063;
  color: #FFFFFF;
  font-family: 'Anton', sans-serif; 
  padding: 0.75rem 2rem; 
  font-size: 1.125rem;
  transform: rotate(0deg);
  font-weight: bold; 
  text-transform: uppercase; 
  border-radius: 0.5rem; 
  margin-bottom: 2rem;
}

.submit-button {
  /* Inherit styles from signup-button */
  @apply signup-button; 

  /* Remove rotation */
  transform: none; 
}

.signup-button:hover {
  background-color: #D6B57A;
}

.bg-black {
  background-color: #050606;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.12'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
background-repeat: repeat; 
}

.text-white {
  color: #FFFFFF; /* Updated white */
}

.bg-gold {
  background-color: #CFA063; /* Gold for that pop */
}

.font-anton {
  font-family: 'Anton', sans-serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.font-yellowtail {
  font-family: 'Yellowtail', cursive;
}

.card-title {
  font-family: 'Anton', sans-serif;
  text-transform: uppercase;
  font-size: 3.25rem; /* Increased font size from 3rem to 3.75rem */
  line-height: 1;
}

.contact-input {
  padding: 0.75rem 1rem;
  border: 1px solid #f0f0f0; /* Light grey/off-white border */
  border-radius: 0.5rem; /* Rounded corners */
  background-color: #050606; /* Black background */
  color: #FFFFFF; /* White text */
  font-family: 'Poppins', sans-serif; /* Match the font */
}

.jotform-wrapper {
  position: relative;
  width: 100%;
  min-height: 800px;
  overflow: visible;
}

.jotform-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 800px;
}

@media screen and (max-width: 768px) {
  .jotform-wrapper,
  .jotform-wrapper iframe {
    min-height: 1000px; /* Increase height for mobile devices */
  }
}